import logo from './logo.svg';
import './App.css';

function App() {
    return ( <
        div className = "App" >
        <
        header className = "App-header" >
        <
        img src = 'https://assets.yorgis.net/db.jpg?t=2022-11-28T18%3A18%3A35.749Z'
        className = "App-logo"
        alt = "logo" / >


        <
        /header> <
        /div>
    );
}

export default App;
